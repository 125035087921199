import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'

import Nav from './components/Nav'
import Footer from './components/Footer.jsx'

import Home from './pages/Home'
import Pricing from './pages/Pricing'
import GetInTouch from './pages/GetInTouch'

import './style/index.css'

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#CD071E'
        }
      }}
    >
      <Helmet>
        <title>CREAMODA</title>
        <meta
          name="description"
          content="Introducing CREAMODA, an unprecedented clothing generative engine. At CREAMODA, we offer an intelligent fashion platform designed to bring your creative ambitions to life. With our state-of-the-art generative clothing engine, CREAMODA transforms your vision into top-selling fashion, seamlessly and efficiently. Whether you’re shaping trends or creating unique designs, CREAMODA empowers you to bring your ideas to market with ease."
        />
        <meta
          name="keywords"
          content="AI fashion creation,Generative clothing engine,AI-driven fashion platform,Fashion design automation,Seamless fashion design,Top-selling fashion designs,AI fashion technology,Intelligent fashion design,Creative fashion platform,Automated garment creation"
        />
      </Helmet>
      <div style={{ position: 'relative' }}>
        <BrowserRouter>
          <Nav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/getintouch" element={<GetInTouch />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </ConfigProvider>
  )
}
export default App
