import React from 'react'
import styled from '@emotion/styled'
import { isBrowser, isTablet } from 'react-device-detect'

import BlackBackGround from '../../components/BlackBackground'

import Header from './components/Header'
import FormBox from './components/FormBox'

const GetInTouch = () => {
  return (
    <Container>
      <BlackBackGround />
      <Wrapper isBrowser={isBrowser} isTablet={isTablet}>
        <Header />
        <FormBox />
      </Wrapper>
    </Container>
  )
}
const Container = styled.div`
  height: calc(100vh - 116px);
`
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  ${props => !props.isBrowser && `flex-direction: column;`}

  padding: 8px ${props => (props.isBrowser ? '100px' : props.isTablet ? '40px' : '20px')};
`

export default GetInTouch
