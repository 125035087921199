import React, { useState } from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

// import Logo from '../../../assets/img/Logo.svg'
import Nav from '../../../assets/img/navIcon.svg'

const LogoUrl = 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/AIMODA_small.svg'

const Mobile = ({ isTablet }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const onNavClick = () => {
    setMenuVisible(!menuVisible)
  }

  return (
    <Container isTablet={isTablet}>
      {/* <img src={LogoUrl} alt="CREAMODA logo" /> */}
      <h1>CREAMODA</h1>
      <img src={Nav} alt="CREAMODA logo" onClick={onNavClick} />
      {menuVisible && (
        <Menu onClick={onNavClick}>
          <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/">
            Features
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/pricing">
            Pricing
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? 'active-link' : 'inactive-link')} to="/getintouch">
            Get In Touch
          </NavLink>
        </Menu>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: #000;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${props => (props.isTablet ? '40px' : '20px')};
  position: sticky;
  top: 0;
  z-index: 100;

  h1 {
    color: #fff;
    margin-right: 36px;
    font-family: 'Inter';
  }
`
const Menu = styled.div`
  background: #010101;
  height: 100px;
  width: 120px;
  position: absolute;
  right: 0;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 20px;

  a {
    text-decoration: unset;
    position: relative;
    color: #fff;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */

    margin-bottom: 16px;
    padding: 0 8px;
  }

  .active-link {
    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 16px);
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      bottom: -2px;
      background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%);
    }
  }
`

export default Mobile
