import React from 'react'

import { isMobile, isTablet, isBrowser } from 'react-device-detect'

import Browse from './components/browse'
import Tablet from './components/tablet'
import Mobile from './components/mobile'

// import TrendEngineImg from '../../../../assets/img/trend_engine.svg'
// import SalesDrivenAIDesign from '../../../../assets/img/sales_driven_ai_design.svg'
// import AIPatternMaking from '../../../../assets/img/ai_pattern_making.svg'
// import SmartSupplyChain from '../../../../assets/img/smart_supply_chain.svg'

const WhatWeDo = () => {
  const featureList = [
    {
      key: 'TREND ENGINE',
      title: 'TREND ENGINE',
      img: 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/aa4.svg',
      content:
        'We don’t merely follow trends; we create them. With a deep understanding of the zeitgeist, we shape market direction, influence decisions, and inspire innovation to define the trends ourselves.'
    },
    {
      key: 'SALES-DRIVEN AI DESIGN',
      title: 'SALES-DRIVEN AI DESIGN',
      img: 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/aa3.svg',
      content:
        'Leverage AI-driven design to capture the latest market trends. Our system crafts experiences and products tailored to current demands, user preferences, and cultural insights, ensuring top-selling success.'
    },
    {
      key: 'AI PATTERN MAKING',
      title: 'AI PATTERN MAKING',
      img: 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/aa2.svg',
      content:
        'Our advanced AI pattern-making technology revolutionizes garment design with precision. From concept to production, we streamline the workflow for greater efficiency, reduced costs, and perfect execution.'
    },
    {
      key: 'SMART SUPPLY CHAIN',
      title: 'SMART SUPPLY CHAIN',
      img: 'https://aimoda-ai.oss-us-east-1.aliyuncs.com/aimoda-homepage-image/aa1.svg',
      content: 'Bring your designs to life effortlessly. Our intelligent supply chain guarantees that your generative designs transition smoothly from concept to reality efficiently.'
    }
  ]

  return (
    <>
      {isBrowser && <Browse featureList={featureList} />}
      {isTablet && <Tablet featureList={featureList} />}
      {isMobile && !isTablet && <Mobile featureList={featureList} />}
    </>
  )
}

export default WhatWeDo
