import React from 'react'
import styled from '@emotion/styled'

const Thank = ({ featureList }) => {
  return (
    <Container>
      <Wrapper>
        <Box>
          <Title>Our Achievement</Title>
          <Description>With our super powers we can reach this</Description>
        </Box>
        <Box>
          <GroupBox>
            {featureList.map(feature => (
              <Feature key={feature.key}>
                <FeatuerImg>
                  <img src={feature.img} alt={feature.title} />
                </FeatuerImg>
                <div>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                  <FeatureContent>{feature.content}</FeatureContent>
                </div>
              </Feature>
            ))}
          </GroupBox>
        </Box>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 96px;
  padding: 80px 112px;
`
const Wrapper = styled.div`
  display: flex;
`
const Box = styled.div`
  width: calc(50% - 16px);
  margin-bottom: unset;
  box-sizing: border-box;

  &:first-child {
    margin-right: 32px;
  }
`
const Title = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Display lg / Bold */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.2px;
  margin-bottom: 8px;
`
const Description = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Body lg / Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
`
const GroupBox = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Feature = styled.div`
  width: 50%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`
const FeatuerImg = styled.div`
  padding: 8px;
  margin-right: 16px;
  height: 32px;
  width: 32px;

  & > img {
    width: 100%;
  }
`
const FeatureTitle = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Display md/Bold */
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 125% */
  letter-spacing: -0.9px;
`
const FeatureContent = styled.div`
  color: var(--Neutral-900, #111827);
  font-feature-settings: 'liga' off;

  /* Body md / Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`

export default Thank
