import React, { useState } from "react"
import styled from "@emotion/styled"
import { isMobile } from "react-device-detect"

import BlackBackGround from "../../components/BlackBackground"
import Header from "./components/Header/index"
import LogoCloud from "./components/LogoCloud"
import WhatWeDo from "./components/WhatWeDo"
import RealStories from "./components/RealStories"
import Achievement from "./components/Achievement"
import QRCode from "./components/QRCode"

const Home = () => {
  const [qrCodeVisible, setQrCodeVisible] = useState(false)

  // get start 按钮点击事件
  const handleStart = () => {
    if (isMobile) {
      window.open("https://create.creamoda.ai/", "_blank")
    } else {
      setQrCodeVisible(true)
    }
  }

  return (
    <Container className="home-page-container">
      <BlackBackGround />
      <Header handleStart={handleStart} />
      <LogoCloud />
      <WhatWeDo />
      <RealStories />
      <Achievement />
      <QRCode visible={qrCodeVisible} setVisible={setQrCodeVisible} />
    </Container>
  )
}

const Container = styled.div``

export default Home
