import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Input, Button } from 'antd'
import { isBrowser, isTablet, isMobile } from 'react-device-detect'

const { TextArea } = Input

const FormBox = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  return (
    <FormBoxContainer isBrowser={isBrowser}>
      <FormItem>
        <Label>
          Your name
          <RequiredMark>*</RequiredMark>
        </Label>
        <Content>
          <Input
            value={name}
            onChange={e => {
              setName(e.target.value)
            }}
          />
        </Content>
      </FormItem>
      <FormItem>
        <Label>
          Email address
          <RequiredMark>*</RequiredMark>
        </Label>
        <Content>
          <Input
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
        </Content>
      </FormItem>
      <FormItem>
        <Label>Phone number</Label>
        <Content>
          <Input
            value={phone}
            onChange={e => {
              setPhone(e.target.value)
            }}
          />
        </Content>
      </FormItem>
      <FormItem>
        <Label>Message</Label>
        <Content>
          <TextArea
            value={message}
            showCount
            maxLength={255}
            onChange={e => {
              setMessage(e.target.value)
            }}
            style={{ height: 120, resize: 'none' }}
          />
        </Content>
      </FormItem>
      <FormItem>
        <StyledButtom>Send Message</StyledButtom>
      </FormItem>
    </FormBoxContainer>
  )
}

const FormBoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: ${props => (props.isBrowser ? '548px' : '100%')};
  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
  ${props => props.isBrowser && 'margin-left: 180px;'}
  z-index: 1;

  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
`
const FormItem = styled.div`
  margin-bottom: 10px;
`
const Label = styled.div`
  margin-bottom: 12px;
`
const Content = styled.div``
const RequiredMark = styled.span`
  color: #f00;
`
const StyledButtom = styled(Button)`
  height: 56px;
  border-radius: 4px;
  background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;

  color: #fff;
  text-align: center;
  font-feature-settings: 'liga' off;
  /* Body md/SemiBold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  &:hover {
    background: linear-gradient(135deg, #f70c18 0%, #cd071e 100%) !important;
  }
`

export default FormBox
