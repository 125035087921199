import React from 'react'
import styled from '@emotion/styled'

import { isBrowser, isTablet, isMobile } from 'react-device-detect'

const Header = () => {
  return (
    <Text>
      <Title isBrowser={isBrowser} isTablet={isTablet} isMobile={isMobile}>
        GET IN TOUCH
      </Title>

      <Description>We are ready for you!</Description>
    </Text>
  )
}

const Text = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
`
const Title = styled.div`
  color: #fff;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: ${props => (props.isBrowser ? '60px' : props.isTablet ? '40px' : '36px')};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.5px;
  text-transform: uppercase;
`
const Description = styled.div`
  color: #e0e0e0;
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
`

export default Header
